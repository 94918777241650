/* Styling for the title */
.welcome-msg {
  margin: 5vh 0;
  color: white;
  animation: bounce 2s infinite alternate, colorChange 3s infinite alternate, scaleUp 4s infinite alternate;
  text-align: center;
}

/* Keyframes for bouncing animation */
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);}
  100% {
    transform: translateY(0);
  }
}

/* Keyframes for color change animation */
@keyframes colorChange {
  0% {
    color: #ff4081;
  }
  50% {
    color: #ffa726;
  }
  100% {
    color: #26c6da;
  }
}

/* Add a background image to the entire app */
body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background: url('/public/classroom.jpg') no-repeat center center fixed;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Container for the entire app */
.app-container {
  background-color: transparent;
  padding: 20px;
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

/* Main content wrapper */
.main-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

/* Canvas wrapper to fix the avatar to the left */
.canvas-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 60vw;
  height: 100vh;
  overflow: auto;
  background: transparent;
}

.board {
  position: relative;
  left: 20vw;
  bottom: 15vh;
  width: 40vw;
  height: 60vh;
  background: url('/public/page.png') no-repeat center center;
  background-size: contain;  /* Ensure the image scales properly */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;  /* Ensure the content does not overflow out of the board */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .board {
    height: 60vh;  /* Adjust height for smaller screens */
    width: 60vw;  /* Adjust width to take more screen space */
    padding: 3vw;  /* Reduce padding */
    margin: 3vw;  /* Reduce margin */
    left: 25vw;  /* Center more appropriately */
    bottom: 5vh;  /* Reduce bottom positioning */
  }
}

.streaming-text {
  background-color: transparent;
  text-align: left;
  color: #fff;
  padding: 3vw;
  height: 30%;  /* Adjust to take the full height of the board */
  max-height: 50%;  /* Ensure it doesn't exceed the board's height */
  width: 70%;  /* Adjust to take the full width of the board */
  overflow-y: scroll;  /* Enable vertical scrolling if content overflows */
  font-weight: bold;
  line-height: 1.5;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .streaming-text {
    padding: 2vw;  /* Adjust padding for smaller screens */
    font-size: 14px;  /* Adjust font size */
  }
}

/* Input bar styling (WhatsApp theme) */
.input-bar {
  display: flex;
  flex-wrap: wrap;
  border-radius: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ECE5DD; /* WhatsApp light gray background */
  border-top: 1px solid #D1D8E0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 100vw;
  position: fixed;
  bottom: 2vh;
  z-index: 10;
  gap: 10px;
}

/* Text input styling (WhatsApp theme) */
.text-input {
  flex: 1 1 auto;
  min-width: 150px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #B0B3B8; /* WhatsApp light gray border */
  border-radius: 20px;
  outline: none;
  color: #000; /* Dark text for better readability */
  background-color: #FFFFFF; /* White background for the input box */
}

/* Icon button styling (WhatsApp theme) */
.icon-button {
  flex: 0 0 auto;
  background-color: #25D366; /* WhatsApp green background */
  border: none;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  font-size: 18px;
  padding: 8px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-button:hover {
  background-color: #128C7E; /* Slightly darker green on hover */
}

.icon-button svg {
  width: 20px;
  height: 20px;
}

/* Speak button container */
.speak-button-container {
  position: fixed;
  top: 2vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
}

/* Speak button styling */
.speak-button-container button {
  background-color: #FF5722; /* Vibrant orange color */
  border: none;
  border-radius: 50px;
  color: white;
  cursor: pointer;
  font-size: 20px;
  padding: 15px 30px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: pulse 1.5s infinite;
}

.speak-button-container button:hover {
  background-color: #E64A19;
  transform: scale(1.05);
}

/* Keyframes for pulsing animation */
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.user-text {
  color: blue;
}

.system-text {
  color: white;
}

/* Eraser Button */
#eraser {
  position: absolute;
  top: 30%;
  right: 10%;
  background-color: #c64d1c;
  border: none;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 20px;
  padding: 10px;
  color: white;
}

#eraser:hover {
  background-color: #d8cd59;
}

.icon-button .faMicrophone {
  color: gray;
}

.icon-button.recording {
  color: white;
  background-color: red /* WhatsApp green background */

}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.user-form-container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 20px;
  box-sizing: border-box;
}

.background-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  opacity: 0.3;
}

.user-form {
  position: relative;
  z-index: 2;
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}

.form-title {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
  color: #2e7d32;
  font-weight: bold;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #2e7d32;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #c8e6c9;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

.subject-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.subject-buttons button {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #c8e6c9;
  border-radius: 5px;
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  color: #2e7d32;
}

.subject-buttons button:hover {
  background-color: #a5d6a7;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.subject-buttons img {
  width: 40px;
  height: 40px;
  margin-bottom: 5px;
}

@media (max-width: 600px) {
  .user-form {
    padding: 20px;
  }

  .form-title {
    font-size: 20px;
  }

  .subject-buttons button {
    flex: 1 1 100%;
  }
}

.file-preview-container {
  position: relative;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.file-preview img, 
.file-preview video {
  width: 60px; /* Adjust size as needed */
  height: 60px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 10px;
}

.file-preview p {
  font-size: 16px;
  color: #333;
  margin: 0;
}

.remove-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  padding: 4px 8px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  line-height: 12px;
}

.remove-button:hover {
  background-color: rgba(255, 0, 0, 0.7);
}

@media (max-width: 600px) {
  .file-preview img, 
  .file-preview video {
    width: 50px;
    height: 50px;
  }

  .remove-button {
    top: -8px;
    right: -8px;
    width: 18px;
    height: 18px;
    font-size: 10px;
    line-height: 10px;
  }

  .file-preview p {
    font-size: 14px;
  }
}

/* App.css */
.navigation {
  background-color: #2c3e50; /* Dark blue shade */
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-link {
  color: #ecf0f1; /* Soft white */
  text-decoration: none;
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-link:hover, .nav-link:focus {
  background-color: #34495e; /* Slightly lighter blue */
  color: #1abc9c; /* Soft teal */
}

.settings-container {
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin: 20px;
  width: fit-content;
}

.settings-container h2 {
  color: #333;
}

.settings-container form {
  display: flex;
  flex-direction: column;
}

.settings-container label {
  margin: 10px 0;
}

input[type="radio"] {
  margin-right: 8px;
}

fieldset {
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px 0;
}

legend {
  padding: 0 10px;
  color: #555;
}

.usage {
  background-color: #e8e8e8;
  padding: 10px;
  margin-top: 20px;
  border-radius: 4px;
}

button {
  background-color: #1a73e8;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #0f62ae;
}

.settings-container {
  justify-content: center;

  padding: 20px;
  background-color: #ffffff; /* Bright background for the form */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%; /* Responsive width */
  max-width: 80%; /* Maximum width of the form */
  box-sizing: border-box; /* Includes padding and border in the element's total width and height */
  margin: 5vw;
}

@media (max-width: 600px) {
  .settings-container {
    padding: 10px; /* Reduce padding on smaller screens */
    font-size: 14px; /* Smaller text to fit better on the screen */
  }

  input[type="text"], input[type="radio"], button {
    width: 100%; /* Full width for better touch targets */
    margin-top: 5px; /* More spacing around elements */
  }

  label {
    display: block; /* Ensure labels are above inputs */
    margin-bottom: 5px; /* Space below each label */
  }

  .usage {
    font-size: 13px; /* Smaller font for usage details */
  }
}


.card {
  background: white;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 350px;
}

.card-header {
  background-color: #0056b3;
  color: white;
  padding: 12px 20px;
  font-size: 20px;
}

.card-body {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.input-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.input-group input, .input-group select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-btn:hover {
  background-color: #218838;
}
.add-subtopic-button {
  background-color: #4CAF50; /* Green background */
  color: white; /* White text */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-subtopic-button:hover {
  background-color: #45a049; /* Darker green background on hover */
}

.add-subtopic-button:focus {
  outline: none; /* Removes the default focus outline */
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Adds a light blue shadow for accessibility */
}

.add-subtopic-button:active {
  background-color: #3e8e41; /* Even darker green when the button is clicked */
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2); /* Inner shadow to give a feeling of depth */
}

.sub-topic {
  background-color: #f8f9fa; /* Light gray background */
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.sub-topic h4 {
  color: #333;
  margin-bottom: 10px; /* Spacing between title and form elements */
}

.form-group {
  margin-bottom: 10px; /* Space between each form group */
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #666; /* Dark gray color for text */
  font-weight: bold;
}

.form-group select, .form-group input {
  width: 100%; /* Full width of the container */
  padding: 8px;
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 4px;
  box-sizing: border-box; /* Border box sizing to include padding in width */
}

.form-group select:hover, .form-group input:hover {
  border-color: #888; /* Darker border on hover */
}

.form-group select:focus, .form-group input:focus {
  outline: none;
  border-color: #0056b3; /* Blue border for focus */
  box-shadow: 0 0 0 2px rgba(0, 86, 179, 0.25); /* Glowing effect for focus */
}


.form-group {
  margin-bottom: 15px; /* Space between form groups */
  background-color: #fff; /* White background for focus */
  border-radius: 5px; /* Rounded corners */
  padding: 10px; /* Padding around the form elements */
  box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

.form-group label {
  display: block; /* Ensures the label is on its own line */
  margin-bottom: 5px; /* Space below the label */
  font-size: 16px; /* Slightly larger font size for readability */
  color: #333; /* Dark grey for better readability */
  font-weight: bold; /* Bold font weight for clarity */
}

.form-group select {
  width: 100%; /* Full width of the container */
  padding: 8px 10px; /* Padding for better click area and aesthetics */
  border: 1px solid #ccc; /* Light grey border */
  border-radius: 4px; /* Rounded corners */
  appearance: none; /* Removes default system styling */
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center; /* Positioning the dropdown arrow */
  background-size: 16px; /* Size of the dropdown arrow */
  color: #666; /* Text color */
  cursor: pointer; /* Cursor to indicate it's selectable */
}

.form-group select:hover {
  border-color: #888; /* Darker border on hover */
}

.form-group select:focus {
  outline: none;
  border-color: #0056b3; /* Blue border for focus */
  box-shadow: 0 0 0 2px rgba(0, 86, 179, 0.25); /* Glowing effect for focus */
}

